.footer {
    position: relative;
    background-color: #f7f7f7;

    &-top {
        padding: 130px 0;

        p {
            line-height: 30px;
            font-weight: 700;

            span {
                display: block;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                line-height: 30px;
            }
        }

        @media (max-width: 991px) {
            [class^="col-"] {
                margin-bottom: 30px;
            }
        }
    }

    &-form {
        position: relative;
        width: 200px;
        height: 48px;
        margin-left: auto;
        margin-top: 55px;

        input,
        button {
            font-size: 14px;
            height: 100%;
        }

        input {
            width: 100%;
            padding: 14px 30px;
            padding-right: 45px;
            border: 2px solid $color-main;

            &::placeholder {
                color: $color-main;
                opacity: 1;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 1px;
            padding: 6px;
            width: 42px;
            font-weight: 700;
        }

        @media (max-width: 991px) {
            margin-left: 0;
        }
    }

    &-logo {

        img {
            height: 52px;
        }

        .container {
            padding: 41px 0 67px;
            border-top: 1px solid $color-black;
        }
    }

    &-bot {
        font-family: $font-alt;
        padding: 10px;
        color: #fff;
        background-color: $color-black;

        h2 {
            font-size: 15px;
        }

        h3 {
            font-size: 12px;

            img {
                height: 32px;
                margin-left: 42px;
            }
        }

        @media (max-width: 991px) {
            [class^="col-"] {
                padding: 5px 0;
            }
        }
    }
}