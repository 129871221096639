.pageheader {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 42rem;
  text-align: center;
  padding-bottom: 4rem;
  background-image: url('#{$imgPath}/jeremy-zero-oviIAOPmM4k-unsplash.jpg');
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.2);
  }

  &__wrap {
    position: relative;
    width: 100%;
    background-image: url("../images/Med-8_warm.png");
    background-size: cover;
    background-position: center;
  }

  .container {
    z-index: 1;
  }

  &-title {
    z-index: 1;

    h1 {
      color: #fff;
      font-size: 8rem;
      // font-weight: 700;
    }
  }

  &-bg {
    overflow: hidden;

    &,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &::after {
      content: "";
      background-color: rgba(#000, 0.5);
    }

    .imgtobg-img {
      width: 100%;
    }
  }

  .breadcrumb {
    padding: 2rem 1rem;
    justify-content: center;
    background-color: transparent;
    margin-bottom: 0;

    &__wrap {
      background-color: #f5f5f5;
    }

    &-item {
      color: #fff;
      font-family: $font-title;
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: capitalize;
      margin-right: 0.5rem;

      a {
        font-weight: 600;

        &:hover {
          color: $color-main;
        }
      }
    }
  }


  @include respond(tabland-min) {
    &__wrap {}
  }

  @include respond(tabland) {
    &-title h1 {
      font-size: 6rem;
    }
  }

  @include respond(phone) {
    height: 25rem;

    &-title h1 {
      font-size: 3rem;
    }
  }
}

.page {}