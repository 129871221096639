html {
  font-size: 62.5%;
  overflow-x: hidden;
  max-width: 100vw;

  &.overflowYStop {
    overflow-y: hidden;
  }

  &.overflowYScroll {
    overflow-y: scroll;
  }

  @include respond(tabland) {
    font-size: 60%;

    &.overflowYStop-min {
      overflow-y: hidden;
    }
  }

  @include respond(tabport) {
    font-size: 58%;
  }

  @include respond(phone) {
    font-size: 55%;
  }
}

body {
  font-size: 15px;
  overflow-x: hidden;
  font-family: $font-common;
  max-width: 100%;

  *::-moz-selection,
  *::selection {
    color: #fff;
    background-color: $color-main;
  }
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
  transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -webkit-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;

  &:hover {
    color: $color-main;
    text-decoration: none;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

p {
  font-family: $font-common;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;

  &.p {
    &-large {
      font-size: 1.8rem;
      line-height: 1.8;
    }
  }

  i {
    font-weight: 600;
  }

  @include respond(tabland) {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

.wow {
  animation-delay: 0.5s;
}

.container.row {
  margin: 0 auto;
}

.container {
  @include respond(tabland-min) {
    max-width: 990px;
  }

  @include respond(smdesktop-min) {
    max-width: 1200px;
  }

  @include respond(bgdesktop-min) {
    max-width: 1300px;
  }

  @media (min-width: 1551px) {
    max-width: 1494px;
  }
}

.hide,
.hidden {
  display: none !important;
}

i.fa-phone {
  transform: rotate(90deg);
}

.sitecontent {
  // min-height: 100vh;
}

.box {
  padding: 3rem;
  background-color: rgba(#000, 0.8);
  margin-bottom: 3rem;

  &-inner {
    background-color: rgba(#fff, 0.15);
    padding: 2rem;
  }
}

.section {
  position: relative;
  padding: 10rem 0;
  overflow: hidden;

  &-bg {
    z-index: -1;
    @include absoldefault;

    &::after {
      content: "";
      @include absoldefault;
      background: #000;
      opacity: .7;
    }

    img {
      @include imgfit;
    }
  }

  &-head {
    margin-bottom: 4rem;
  }

  @include respond(tabland) {
    padding: 4rem 0;

    &-head {
      margin-bottom: 2rem;
    }
  }
}

.post {
  &-content {
    .heading {
      font-size: 4rem;

      &-mini {
        margin-top: 2rem;
      }
    }

    .list-content {
      li {
        font-size: 1.75rem;

        &::before {
          top: .3rem;
        }
      }
    }
  }
}

.slick-slide {

  &,
  &:focus {
    outline: none;
  }
}

.form-control {
  font-size: 15px;
}