.header {
  z-index: 997;
  position: relative;
  color: $color-black;
  background-color: #fff;
  transition: all 0.5s ease;

  &-top {
    padding: 15px 0 14px;
    background-color: #fff;
    border-bottom: 1px solid $color-main;
    transition: all 0.5s ease;

    span {
      position: relative;
      font-size: 1.5rem;
      font-weight: 400;
      display: inline-block;

      &:not(:last-child) {
        margin-right: 20px;
      }

      strong {
        margin-right: 10px;
      }

      a:hover {
        color: $color-main;
      }

    }

    .bar-spacer {
      display: inline-block;
      vertical-align: middle;
      width: .1rem;
      height: 1.5rem;
      margin: 0 1.5rem 0 1.2rem;
      background-color: $color-black;
      opacity: .2;
    }

    &-col-rt {
      span {
        font-weight: 500;

        i {
          color: $color-main;
        }

      }
    }

    .social-icon {
      &::before {
        display: none;
      }

      &-group {
        display: inline-block;
        // margin-bottom: 1rem;
      }

      i {
        font-size: 1.5rem;
      }

      a:hover {
        color: $color-main;
      }
    }

    &-row {
      align-items: center;
      overflow: hidden;
    }
  }

  &-main {
    padding: 15px 0 20px;
  }

  &-logo {
    text-align: center;
  }

  &-nav-row {}

  &-togglers {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &-nav-toggle {
    width: 32px;
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    img {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    .close {
      display: none;
    }

    &.active .open {
      display: none;
    }

    &.active .close {
      display: block;
    }
  }

  &-nav-col-nav {
    display: flex;
    align-items: center;
  }

  &-main {}

  &-button {
    a {
      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {}
    }
  }

  @media (min-width: 992px) {}

}

.overlay-nav {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, .95);
  opacity: 0;
  transform: translateY(-100%);
  transition: all .5s ease-out;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  .navigation {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-right: 0;
    height: 100%;
    overflow-y: auto;
  }

  #overlayMenu {
    height: 100%;
    padding: 50px 0;
  }

  .navul {
    flex-flow: column;

    >li {
      &:not(:last-child) {
        margin-bottom: 97px;
      }

      >a {
        font-size: 70px;
        line-height: 84px;

        &:hover {
          font-weight: 700;
        }
      }
    }

    @media (max-width: 991px) {
      >li {
        &:not(:last-child) {
          margin-bottom: 15px !important;
        }

        >a {
          font-size: 18px;
          line-height: normal;
        }
      }
    }
  }
}

.logowrap {
  width: 264px;

  img {
    width: 100%;
    padding: 1rem;
    transition: all 0.5s ease;
  }
}

.phone-call {
  color: #fff;
  background-color: $color-main;
  border-radius: 50%;

  &-wrap {
    z-index: 998;
    position: fixed;
    bottom: 2rem;
    right: 2rem;

    &:hover {
      animation: bounce 1s linear;
    }
  }

  a {
    display: block;
    padding: 2rem;
    cursor: pointer;
  }

  i {
    font-size: 3rem;
  }
}

#contactModal {
  .modal-header {
    color: #fff;
    background-color: $color-main;

    h5 {
      font-family: $font-title;
      font-size: 2rem;
      font-weight: 700;
    }
  }

  .modal-body {
    a {
      font-size: 1.8rem;
      font-weight: 700;

      i {
        color: $color-main;
      }
    }
  }

  .modal-footer {
    button {
      font-size: 1.7rem;
      background-color: $color-main;
      border: 0;
    }
  }
}

#contactPopModal {
  .modal-body {
    text-align: center;

    p {
      margin-bottom: 15px;
    }

    a {
      display: inline-block;
      padding: 10px 20px;
      color: #fff;
      background: linear-gradient(to bottom,
          lighten($color-main, 5%),
          darken($color-main, 5%));

      &:hover {
        background: linear-gradient(to bottom,
            darken($color-main, 5%),
            lighten($color-main, 5%));
      }
    }

    p:nth-child(2n + 1) a {
      background: #000;
    }

    p:nth-child(2n + 1) a:hover {
      background: $color-main;
    }
  }
}

.nav-social {
  @include absoltopcenter;
  right: 0;
  padding-right: 1rem;

  .social-icon {
    margin-right: 1.5rem;
    text-align: center;

    a {
      display: inline-block;
      min-width: 2.5rem;
      min-height: 2.5rem;
    }

    i {
      font-size: 1.5rem;

      &:hover {
        color: $color-sub;
      }
    }
  }

  &::after {
    content: "";
    @include absoltopcenter;
    right: 0;
    width: 1px;
    height: 80%;
    background-color: #fff;
  }

  @include respond(tabland) {
    right: 22%;

    .social-icon i {
      font-size: 1.8rem;

      &:hover {
        color: #555;
      }
    }

    &::after {
      background-color: $color-black;
    }
  }
}

.search {
  &-btn {
    min-width: 1px;
    margin-right: 1rem;
    text-transform: capitalize !important;
    transition: all 0.4s ease;

    &:not([href]):not([tabindex]) {}

    img {
      display: inline-block;
      width: 1.25rem;
      filter: brightness(0) invert(1);
    }

    &:hover {
      img {
        filter: none;
      }
    }

    &-sm {
      position: relative;
      display: inline-block;
      margin-left: 2rem;
      cursor: pointer;

      &::after {
        content: "";
        @include absoltopcenter;
        left: -2rem;
        width: 1px;
        height: 75%;
        background-color: #fff;
        opacity: 0.2;
      }

      img {
        width: 2.5rem;
        filter: brightness(0) invert(1);
      }
    }
  }

  &-popup {
    display: none;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(1);
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
    transition: all 0.5s;
    transition-delay: 0.5s;

    &-box {
      @include absolcenter;
      width: 100%;
      max-width: 90rem;
      padding: 2rem 5rem;
      margin-top: 10rem;
      opacity: 0;
      transition: all 0.5s ease;

      &-inner {
        position: relative;
      }
    }

    &-input {
      color: #fff;
      font-size: 2.5rem;
      height: 4rem;
      padding-right: 6rem;
      border: 0;
      background: transparent;

      &::placeholder {
        color: $color-gray;
      }

      &:focus {
        color: #fff;
        background: transparent;
        outline: none;
        border: none;
        box-shadow: none;

        &::placeholder {
          color: #fff;
        }
      }
    }

    &-line {
      position: absolute;
      bottom: -3rem;
      left: 0;
      right: 0;
      width: 100%;
      height: 0.5rem;
      background-color: rgba(#fff, 0.2);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff;
        transition: all 0.4s ease;
      }
    }

    &-input:hover~&-line::after {
      width: 5%;
    }

    &-input:focus~&-line::after {
      width: 100%;
    }

    &-submit {
      position: absolute;
      top: 0;
      right: 0;
      width: 5rem;
      background: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 100%;
        filter: brightness(0) invert(1);
      }
    }

    &-exit {
      position: absolute;
      top: 15%;
      right: 15%;
      width: 5rem;
      margin-top: -10rem;
      opacity: 0;
      transition: all 0.5s ease;

      img {
        width: 100%;
        filter: brightness(0) invert(1);
        cursor: pointer;
        transition: all 0.3s ease;
      }

      &:hover img {
        filter: none;
      }
    }

    &-toggle {
      cursor: pointer;
    }

    &.active {
      display: block;
    }

    &.open {
      transform: scale(1);
      opacity: 1;
      transition-delay: 0s;
    }

    &.open &-box,
    &.open &-exit {
      opacity: 1;
      margin-top: 0;
      transition-delay: 0.5s;
    }
  }
}

.scrolltop-btn {
  z-index: 9;
  position: fixed;
  bottom: 4rem;
  right: -10rem;
  transition: all 0.4s ease;

  &.active {
    right: 4rem;
  }

  a {
    font-size: 2.25rem;
    padding: 1rem 1.75rem;
    display: inline-block;
    color: $color-black;
    background-color: $color-main;

    &:hover {
      color: #fff;
      background-color: $color-sub;
    }
  }

  @include respond(tabland) {
    bottom: 12rem;

    &.active {
      right: 3rem;
    }
  }
}

.login-avatar {
  width: 4rem;
  height: 4rem;
  border: 1.5px solid rgba(#000, .35);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  i {
    color: $color-main;
  }

  img {
    max-width: 100%;
  }
}