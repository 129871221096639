.section {
    &-why {
        .container {
            padding: 0;
            max-width: 1980px;
        }
    }

    &-keep {
        padding: 98px 0 20px;
    }

    &-homesearch {
        padding: 99px 0 142px;
        margin-bottom: 27px;

        .section-bg::after {
            opacity: .25;
        }
    }

    &-ctanewsletter {
        padding: 132px 0;
    }

    &-dropline {
        padding: 164px 0 211px;
    }

    &-map {
        .container {
            max-width: 1920px;
            @include margin-auto;
            padding: 0;
        }
    }

    @media (max-width: 991px) {

        &-homesearch,
        &-ctanewsletter,
        &-dropline {
            padding: 50px 0;
        }
    }
}

.herohome {
    position: relative;



    .link-box,
    .link-box-alt,
    .link-box-white,
    .link-box-white-alt {
        width: 332px;
        display: block;
        margin-bottom: 16px;
    }

}

.why {
    margin-top: 12px;

    &-row {
        margin: 0;
    }

    &-col {
        padding: 0;
    }

    &-card {
        position: relative;
        width: 100%;
        height: 637px;
        overflow: hidden;
        text-align: center;

        &-img {
            z-index: 0;
            @include absoldefault;
            @include imgfit;
        }

        &-text {
            @include absolcenter;

            .heading {
                font-size: 60px;
                font-weight: 700;
                line-height: 55px;
            }
        }

        &-btn {
            position: absolute;
            top: 0;
            left: 0;
        }

        &-btn-static {
            margin-top: 25px;
        }
    }

    @media (max-width: 1400px) {
        &-card {
            height: 550px;

            &-text {
                .heading {
                    font-size: 40px;
                    line-height: normal;
                }
            }
        }
    }

    @media (max-width: 767px) {
        &-card {
            height: 320px;

            &-btn {
                padding: 20px 50px;
            }
        }
    }

    @media (max-width: 567px) {
        &-card {
            &-text {
                width: 100%;
                padding: 10px 25px;

                .heading {
                    font-size: 28px;
                }
            }

            &-btn {
                padding: 15px 30px;
            }
        }
    }
}

.keep {
    position: relative;

    .section-head {

        .heading-sub,
        .social-icon-group {
            display: inline-block;
            vertical-align: middle;
        }

        .social-icon-group {
            margin-left: 38px;
        }

        .social-icon {
            width: 22px;
            height: 22px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            img {
                filter: brightness(0);
            }
        }
    }

    &-slider {
        padding: 0;

        &-wrap {
            position: relative;
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 -7px;
            padding-right: 135px;
        }

        .slick {
            &-slide {
                padding: 0 15px;
                overflow: hidden;
            }

            &-list {
                overflow: visible;
            }

            @media (max-width: 991px) {
                &-slide {
                    padding: 0 6px;
                }
            }
        }

        .slider-nav {
            position: absolute;
            bottom: calc(100% + 50px);
            right: 0;

            @media (max-width: 991px) {
                position: relative;
                bottom: auto;
                right: auto;
                margin: 20px 15px;
            }
        }

        .item {
            .grid-container {
                margin: 0 -15px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 0px 0px;
                grid-template-areas:
                    "grid-1 grid-2 grid-4 grid-6"
                    "grid-1 grid-3 grid-5 grid-7";
            }

            .grid-item {
                padding: 0 15px 15px;
            }

            .grid-item-1 {
                grid-area: grid-1;
            }

            .grid-item-2 {
                grid-area: grid-2;
            }

            .grid-item-3 {
                grid-area: grid-3;
            }

            .grid-item-4 {
                grid-area: grid-4;
            }

            .grid-item-5 {
                grid-area: grid-5;
            }

            .grid-item-6 {
                grid-area: grid-6;
            }

            .grid-item-7 {
                grid-area: grid-7;
            }

            @media (max-width: 1200px) {
                .grid-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr 1fr 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                        "grid-item-1 grid-item-1"
                        "grid-item-2 grid-item-3"
                        "grid-item-4 grid-item-5"
                        "grid-item-6 grid-item-7";
                    margin: 0 -10px;
                }

                .grid-item {
                    padding: 0 10px 10px;
                }

                .grid-item-1 {
                    grid-area: grid-item-1;
                }

                .grid-item-2 {
                    grid-area: grid-item-2;
                }

                .grid-item-3 {
                    grid-area: grid-item-3;
                }

                .grid-item-4 {
                    grid-area: grid-item-4;
                }

                .grid-item-5 {
                    grid-area: grid-item-5;
                }

                .grid-item-6 {
                    grid-area: grid-item-6;
                }

                .grid-item-7 {
                    grid-area: grid-item-7;
                }
            }

            @media (max-width: 767px) {
                .grid-container {
                    display: flex;
                    flex-wrap: wrap;
                }

                .grid-item {
                    width: 50%;
                }
            }

            @media (max-width: 567px) {
                .grid-item {
                    width: 100%;
                }
            }

        }
    }

    &-img {
        position: relative;
        width: 100%;
        perspective: 3000px;
        overflow: hidden;

        img {
            @include imgfit;
        }

        @media (min-width: 1201px) {
            img {
                height: 370px;
            }

            &-lg {
                img {
                    height: 760px;
                }
            }
        }

        @media (max-width: 1200px) {

            img,
            &-lg img {
                height: 280px;
            }
        }

        @media (max-width: 767px) {

            img,
            &-lg img {
                height: 200px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &-overlay {
            padding: 20px;
            background-color: #fff;
            border: 5px solid $color-main;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transition: all .3s ease-out;

            h3 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 20px;
            }

            a {
                font-size: 20px;
                line-height: 30px;

                &:hover {
                    color: $color-main;
                }
            }
        }

        @media (min-width: 1201px) {
            &-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 44px 70px 120px 50px;
                transform: rotateY(-90deg) translateZ(40px);
                transform-origin: 0 0;
                opacity: 0;

                h3 {
                    margin-bottom: 55px;
                }

                a {
                    position: absolute;
                    bottom: 50px;
                }
            }

            &:hover &-overlay {
                transform: rotateY(0);
                opacity: 1;
            }
        }

        @media (max-width: 1600px) {
            &-overlay {
                padding: 30px;

                h3 {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }
        }

        @media (max-width: 991px) {
            &-overlay {
                padding: 15px;
                border-width: 2px;

                h3 {
                    font-size: 14px;
                }

                p {
                    font-size: 12px;
                }

                a {
                    font-size: 12px;
                }
            }
        }
    }
}

.homesearch {
    &-box {
        max-width: 617px;
        @include margin-auto;

        &-nav {
            display: flex;

            .btn {
                font-size: 17px;
                line-height: 21px;
                min-width: 95px;
                padding: 12px 27px;
                background-color: #F0F0F0;
                outline: none;

                &.active {
                    background-color: #fff;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        &-form {
            position: relative;
            width: 100%;
            // height: 70px;

            &.active {
                display: block;
            }


            input {
                font-size: 23px;
                width: 100%;
                height: 100%;
                padding: 22px;
                padding-right: 90px;
                border: 0;

                &::placeholder {
                    text-align: center;
                    text-transform: uppercase;
                }

                &:not(.active) {
                    display: none;
                }
            }

            &-input {}

            button {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 85px;
                height: 100%;
                min-width: 1px;

                img {
                    width: 18px;
                }
            }
        }
    }
}

.ctanewsletter {
    &-box {
        max-width: 700px;
        @include margin-auto;
        text-align: center;

        .heading {
            &-bar-alt {
                @include margin-auto;
            }
        }
    }

    .section-head {
        margin-bottom: 77px;
    }

    &-form {
        position: relative;
        width: 100%;
        height: 70px;

        input,
        button {
            height: 100%;
        }

        input {
            padding: 26px 29px;
            padding-right: 170px;
            width: 100%;
            color: #fff;
            border: 2px solid #fff;
            background-color: transparent;

            &::placeholder {
                color: #fff;
            }

            &:focus {
                color: #fff;
                background-color: transparent;
            }
        }

        button {
            font-size: 20px;
            padding: 20px 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            min-width: 1px;
        }

        @media (max-width: 991px) {
            button {
                font-size: 16px;
                padding: 15px 20px;
            }
        }
    }
}

.dropline {
    &-form {
        max-width: 867px;
        @include margin-auto;

        [class^="col-"] {
            margin-bottom: 20px;
        }

        label {
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 12.5px;
        }

        button {
            font-size: 18px;
            height: 60px;
            margin-top: 55px;
        }

        input,
        textarea {
            font-size: 20px;
            padding: 18px;
            border: 1px solid #000;
        }

        input {
            height: 64px;
        }

        textarea {
            min-height: 202px;
        }
    }
}

.map {
    width: 100%;
    height: 949px;

    img {
        @include imgfit;
    }

    @media (max-width: 991px) {
        height: 700px;
    }

    @media (max-width: 567px) {
        height: 300px;
    }

}


.navigation.clearfix {
    margin: 0 auto;
    text-align: center;
}

div#wp_page_numbers ul {
    list-style: none;
    display: inline-flex;
}

div#wp_page_numbers li {
    background: #00053c;
    padding: 10px;
    margin: 3px;
    color: white;
}

div#wp_page_numbers li.active_page {
    background: white;
    color: #00053c;
    border: 1px solid #00053c;
}

div#wp_page_numbers li a:hover {
    color: white;
}

li.active_page:hover {
    color: black;
}


select.form-control:not([size]):not([multiple]) {
    height: calc(6.25rem + 2px);
    border-color: black;
    color: black;
}

h2.form-head {
    margin: 0;
    padding: 8px 5px 9px 15px;
    vertical-align: baseline;
    border-bottom: 1px solid rgb(200,200,200);
    background: rgb(238,238,238);
    border-top: 1px solid rgb(200,200,200);
    display: block;
}

.dropline-form label {
    font-size: 20px;
    text-transform: uppercase;
    /* margin-bottom: 17.5px; */
    margin-top: 22px;
}

/*
.proplisting-card-top .fav-btn {
    position: absolute;
    top: -14px !important;
    right: 0;
}

.fav-btn {
    
    width: 98px;
    height: 25px;
   
}*/


.js-gd-add-mylist {
    font-size: 29px;
    float: right;
}

.js-gd-remove-mylist{
font-size: 29px;
    float: right;
}



.img-replace {
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;
}





/* --------------------------------

xpopup

-------------------------------- */

.customization_popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(94, 110, 141, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.customization_popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
    z-index: 99999
}

.customization_popup_container {
    position: absolute;
    width: 30%;
/*     max-width: 400px; */
    margin: 0 auto;
    right: 0;
    top: 0;
/*    height: 100vh;
*/    background: #FFF;
    border-radius: .25em .25em .4em .4em;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatex(400px);
    -moz-transform: translatex(400px);
    -ms-transform: translatex(400px);
    -o-transform: translatex(400px);
    transform: translatex(400px);
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    z-index: 9999999999 !important;
}

.customization_popup_container p {
    padding: 3em 1em;
}

.customization_popup_container .customization_popup_buttons:after {
    content: "";
    display: table;
    clear: both;
}

.customization_popup_container .customization_popup_buttons li {
    float: left;
    width: 50%;
    list-style: none;
}

.customization_popup_container .customization_popup_buttons a {
    display: block;
    height: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFF;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

.customization_popup_container .customization_popup_buttons li:first-child a {
    background: #fc7169;
    border-radius: 0 0 0 .25em;
}

.no-touch .customization_popup_container .customization_popup_buttons li:first-child a:hover {
    background-color: #fc8982;
}

.customization_popup_container .customization_popup_buttons li:last-child a {
    background: #b6bece;
    border-radius: 0 0 .25em 0;
}

.no-touch .customization_popup_container .customization_popup_buttons li:last-child a:hover {
    background-color: #c5ccd8;
}

.customization_popup_container .customization_popup_close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
}

.customization_popup_container .customization_popup_close::before, .customization_popup_container .customization_popup_close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 14px;
    height: 3px;
    background-color: #8f9cb5;
}

.customization_popup_container .customization_popup_close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}

.customization_popup_container .customization_popup_close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 8px;
}

.is-visible .customization_popup_container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
    .customization_popup_container {
        margin: 8em auto;
    }
}


.homesearch-box-form input:not(.active) {
    display: none;
}

.navul>li.has-submenu::after{
    visibility: hidden;
}