.section {
    &-proplisting {}
}

.proplisting {
    &-slider {
        .slick {
            &-dots {
                padding: 0 65px;
                ;
            }
        }
    }

    &-col {
        margin-bottom: 75px;
    }

    &-card {
        &-img {
            height: 240px;
            border-radius: 6px;
            margin-bottom: 10px;
            overflow: hidden;

            img {
                @include imgfit;
            }
        }

        &-cont {}

        &-top {
            position: relative;
            padding-right: 30px;

            .fav-btn {
                position: absolute;
                top: 0;
                right: 0;
            }

        }

        &-text {
            h3 {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 12px;
            }

            p {
                font-size: 18px;
            }
        }

        &-mid {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 13px;
            margin-top: 17px;
            margin-bottom: 8px;
            border-bottom: 1px solid $color-black-alt;
        }

        .link-box {
            font-size: 12px;
            padding: 5px 15px;
            border: 1px solid $color-main;
        }

        &-bot {
            p {
                margin-bottom: 0;
            }
        }
    }
}

.propdetails {
    &-imgshowcase {
        &-row {

            &,
            &-inner {
                margin: 0 -11px;
            }
        }

        &-col {

            &,
            &-inner {
                padding: 0 11px;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin: 0 -11px;
            list-style-type: none;

            &-item {
                width: 50%;
                padding-left: 11px;
                padding-right: 11px;
                cursor: pointer;
            }
        }
    }

    &-img {
        height: 171px;
        margin-bottom: 22px;
        border-radius: 6px;
        overflow: hidden;

        &-lg {
            height: 557px;
        }


        >img {
            @include imgfit;
        }

        &-loader {
            z-index: 1;
            @include absoldefault;
            width: 100%;
            height: 100%;
            background-color: #fff;

            img {
                @include absolcenter;
                width: 50px;
                height: 50px;
            }
        }

        &-hero {
            z-index: 2;
            position: relative;
        }



        @media (max-width: 991px) {
            &-lg {
                height: 300px;
            }

        }

        @media (max-width: 991px) {
            height: 130px;

            &-lg {
                height: 300px;
            }
        }
    }

    @media (max-width: 991px) {
        &-imgshowcase-side {
            margin: 0 -11px;
        }

        &-imgshowcase-side &-img {
            padding: 0 11px;
        }
    }

    &-imgshowcase-slider-nav {
        display: flex;
        justify-content: space-between;

        .slick {
            &-arrow {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                transform: none;
                width: auto;
                height: auto;
                display: inline-block;
                padding: 10px 20px;
                color: $color-main;
                background-color: $color-sub;
                text-indent: 0;
                font-size: 18px;
                line-height: normal;
                transition: all .3s ease-out;

                &:hover {
                    color: $color-sub;
                    background-color: $color-main;
                }

                &::before {
                    display: none;
                }
            }

            &-disabled {
                opacity: 0;
            }

        }
    }

    &-imgshowcase-side {}

    @media (max-width: 660px) {
        &-imgshowcase-main {
            display: none;
        }

        &-imgshowcase-side &-img {
            height: 250px;
        }

        &-imgshowcase-slider-nav {
            padding: 0 11px;
        }

    }

    &-cont {

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 21px 0;
            border-bottom: 1px solid $color-black-alt;

            h3 {
                font-size: 40px;
                font-weight: 700;
            }

            .card-icons {
                img {
                    width: auto;
                    height: 32px;
                }
            }

            .link-box {
                font-size: 18px;
                line-height: normal;
                padding: 8px 60px;
            }

            @media (max-width: 991px) {
                flex-wrap: wrap;

                .card-icons {
                    width: 100%;
                    order: 3;
                    margin-top: 15px;
                }
            }
        }

        &-body {
            padding: 40px 0;
        }

        &-text {
            p {
                font-size: 20px;
                margin-bottom: 25px;
            }
        }

        &-features {
            h4 {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 50px;
            }

            span {
                display: flex;
                margin-bottom: 45px;
            }

            img {
                margin-right: 27px;
            }

            p {}
        }

        &-profile {
            &-img {
                width: 140px;
                height: 140px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 44px;

                img {
                    @include imgfit;
                }
            }

            &-name {
                font-size: 24px;
                font-weight: 700;
            }

            &-info {
                font-size: 20px;
                line-height: 40px;
                display: block;
            }

            &-links {
                margin-top: 45px;

                a {
                    font-size: 16px;
                    padding: 5px 45px;
                    margin-bottom: 10px;
                    margin-right: 20px;

                    &:hover {
                        color: #fff;
                        background-color: $color-main;
                    }
                }
            }
        }

        .card-icons {
            font-size: 16px;

            img {
                width: 52px;
                margin-right: 12px;
            }
        }
    }

}

.about {
    &-row {
        margin: 0 -50px;
    }

    &-col {
        padding: 0 50px 50px;
    }

    p {
        max-width: 500px;
        font-size: 20px;
        margin-bottom: 25px;
    }

    &-img {
        width: 100%;
        height: 500px;

        img {
            @include imgfit;
        }
    }
}

.git {
    .title-bar {
        margin-bottom: 50px;
    }

    p {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

.serv {
    &-row {
        margin: 0 -50px;
    }

    &-col {
        padding: 0 50px 50px;

        @media (min-width: 1201px) {
            max-width: 20%;
        }
    }

    &-card {
        text-align: center;

        &-icon {
            position: relative;
            width: 180px;
            height: 180px;
            @include margin-auto;
            border: 3px solid $color-main;
            border-radius: 50%;

            img {
                @include absolcenter;
            }
        }

        .title-bar {
            margin: 35px 0 15px;
        }

        p {
            font-size: 17px;
            line-height: 21px;
            max-width: 215px;
            @include margin-auto;
        }
    }
}