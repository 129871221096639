.section-slider {
    padding: 0;

    @include respond(tabland-min) {}

    @include respond(tabland) {}
}

#carousel1 {

    .carousel-item {
        height: 950px;

        &-sm {
            height: 480px;
        }

        .carousel-overlay-wrapper {
            background-color: rgba(#000, .22);
        }

        &-nobg .carousel-overlay-wrapper {
            background-color: transparent;
        }

        &-darkerbg .carousel-overlay-wrapper {
            background-color: rgba(#000, .4);
        }

        .imgtobg-o,
        .imgtobg-o-sm {
            position: relative;
            animation: carouimgzoom 60s infinite;
        }

        img {
            min-height: 100%;
        }

        @media (max-width: 1200px) {
            height: 700px;
        }

        @media (max-width: 767px) {
            height: 450px;
        }
    }

    .carousel-fade .carousel-item {
        opacity: 0;
        transition-duration: .6s;
        transition-property: opacity;
    }

    .carousel-fade .carousel-item.active,
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right {
        opacity: 1;
    }

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        opacity: 0;
    }

    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
    }

}

.carousel {
    &-overlay {
        position: relative;
        max-width: 80rem;
        width: 100%;

        &-wrapper {
            color: #fff;
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;

            >.container {
                z-index: 2;
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        }

        &-wrapper.text-center & {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &-wrapper.text-right & {}

        &-wrapper.text-right &-inner {

            a {
                margin-left: 2rem;
            }
        }

        &-wrapper.text-right & {
            order: 2;
            margin-left: auto;
        }

        &-inner {
            padding: 3rem;
            // @include unselect;

            >* {
                overflow: hidden;
            }

            p {
                font-family: $font-common;
                font-size: 17px;
                line-height: 21px;
                font-weight: 300;
                max-width: 400px;
                margin-bottom: 3rem;
            }

            // a {
            //     display: inline-block;
            //     font-family: $font-title;
            //     font-size: 1.6rem;
            //     font-weight: 700;
            //     text-transform: uppercase;
            //     letter-spacing: .5px;
            //     padding: 1rem 2rem;
            //     color: $color-main;
            //     border: 1.5px solid $color-main;
            //     margin-right: 2rem;

            //     i {
            //         font-size: 90%;
            //         margin-right: .5rem;
            //     }

            //     &:hover {
            //         color: #fff;
            //         border-color: #fff;
            //     }
            // }

        }

        &-title {
            position: relative;
            margin-bottom: 3rem;


        }
    }

    &-img {
        @include imgfit;
    }

    .link-box,
    .link-box-alt,
    .link-box-white,
    .link-box-white-alt {
        width: 332px;
        display: block;
        margin-bottom: 16px;
    }

    &-title {
        font-size: 70px;
        font-weight: 700;
        line-height: 84px;

    }

    p {}

    &-sidebar {
        width: 951px;
        position: absolute;
        bottom: 100%;
        right: 0;
        transform: rotate(-90deg);
        transform-origin: 100% 100%;
        text-align: center;
        background-color: #fff;

        a {
            display: block;
            padding: 12px;
        }

        img {
            margin-left: 56px;
        }

        @media (max-width: 567px) {
            bottom: 0;
            width: 100%;
            transform: none;
            border-bottom: 1px solid rgba(#000, .05);
        }
    }

    &-nav {
        position: absolute;
        left: 15%;
        bottom: 60px;
        width: 220px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            // bottom: 20px;
        }

        @media (max-width: 567px) {
            left: 10%;
        }
    }

    &-indicators,
    &-control-prev,
    &-control-next {
        position: static;
        transform: none;
    }

    &-indicators {
        margin: 0;

        li {
            width: 10px;
            height: 10px;
            margin-right: 12px;
            border-radius: 50%;
            border: 2px solid #fff;
            background-color: transparent;

            &.active {
                background-color: #fff;
            }
        }
    }

    &-control {

        &-prev,
        &-next {
            width: 13px;
            opacity: 1;
            color: #fff;
            border-radius: 50%;
            background-color: rgba(#000, 0);

            &:hover {
                opacity: 1;
            }
        }

        // &-prev {
        //     margin-right: 65px;
        // }

        // &-next {
        //     margin-left: 65px;
        // }
    }

    @include respond(tabland-smdesktop) {
        &-overlay {
            flex: 0 0 75%;
        }

        &-sidepic {
            flex: 0 0 25%;
        }
    }

    @include respond(tabland-min) {
        &-overlay {
            padding-top: 15rem;

            &-inner {

                h2,
                h3,
                h4 {
                    font-size: 6rem;
                    letter-spacing: 1px;
                }

                p {
                    // line-height: 3.75rem;
                }
            }
        }
    }

    @include respond(tabland) {
        &-overlay {
            &::after {
                top: 0;
                bottom: auto;
                width: 7rem;
                height: 7rem;
            }

            &-wrapper {
                &::after {
                    height: 20%;
                }
            }

            &-inner {
                a {}

            }
        }

        &-overlay-inner {

            h2,
            h3 {
                font-size: 3.2rem;
            }

            p {
                font-size: 1.7rem;
            }
        }
    }

    @include respond(phone-min) {

        &-overlay {
            &-inner {
                a {}

            }
        }
    }

    @include respond(phone) {
        &-overlay {
            &-title {
                margin-bottom: 1rem;
                line-height: normal;
            }

            &-inner {
                padding: 5rem 3rem;

                h2,
                h3 {
                    font-size: 25px;
                    margin-bottom: .5rem;
                    line-height: normal;
                }

                p {
                    font-size: 1.5rem;
                    margin-bottom: 1.5rem;
                }

                a {
                    font-size: 1.35rem;
                    padding: .75rem 1.5rem;
                }
            }

            &-wrapper.text-right &-inner {
                a {
                    margin: 1rem 0;
                }
            }

            .heading-fancy {

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}