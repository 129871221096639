@mixin absolcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absoltopcenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin absoldefault {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin imgabsol-adjust {
    width: 100%;
    min-height: 100%;
    height: auto;
    max-height: 300%;
}

@mixin imgabsol-adjust--sm {
    width: auto;
    min-width: 100%;
    max-width: 300%;
    height: 100%;
}

@mixin unselect {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

@mixin margin-auto {
    margin-left: auto;
    margin-right: auto;
}

@mixin col-padding {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

@mixin imgfit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}