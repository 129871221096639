.link-box {
    color: #fff;
    background-color: $color-main;

    &,
    &-white,
    &-alt,
    &-alt-white,
    &-border {
        display: inline-block;
        padding: 12px;
        font-size: 17px;
        text-transform: uppercase;
        text-align: center;
    }

    &-alt {
        border: 1px solid #fff;
    }

    &-white {
        color: $color-main;
        background-color: #fff;
    }

    &-border {
        color: $color-main;
        background-color: #fff;
        border: 1px solid $color-main;
    }

    &:hover {
        color: $color-black;
        background-color: $color-sub;
    }
}